import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListControls from './ListControls.js';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './Style.js';

const defaultImage = "shared/connection.png";
class YBButton extends React.Component {
  constructor (props) {
    super(props);
    //set some defaults
    this.props.object.gsImage = "shared/connection.png";
    this.props.object.path = "churches/centerchurch/connection";
    this.props.object.title = "Get Connected"
    this.state = {
      image: defaultImage
    };
  }

  updateState = (url) => {
    if (url !== this.state.image) {
      this.setState({
        image: url
      });
    }
  }

  render() {
    const {classes} = this.props;
    if (this.props.object.gsImage !== undefined) {
      if (this.props.object.gsImage === "") {
        this.updateState(defaultImage);
      } else {
        this.updateState('https://admin.youbelong.church/centerchurch/image/'+this.props.object.gsImage);
      }
    } else if (this.props.object.imageSource !== undefined) {
      if (this.props.object.imageSource === "") {
        this.updateState(defaultImage);
      } else {
        this.updateState(this.props.object.imageSource);
      }
    }
    const labelText = this.props.object.title || "You Belong Card";
    return (
      <React.Fragment>
        <Card raised={true} className={classes.card}>
          <CardHeader
            title="Button"
            classes={{
              root: classes.cardHeader,
              action: classes.action,
            }}
            action={
              <ListControls onDelete={this.props.onDelete}
                onUp={this.props.onUp} onDown={this.props.onDown}/>
            }
          />
          <CardContent className={classes.cardContent}>
            <Card raised={true} className={classes.subcard}>
              <div className={classes.notesButton}>
                <img className={classes.roundImage} src={this.state.image} alt=""/>
                {labelText}
              </div>
            </Card>
          </CardContent>
          <CardActions>
            <div className={classes.flexItem}>
              <TextField className={classes.flexItemInner} type="text" name="gsImage"
                label="GS Image" multiline={true} value={this.props.object.gsImage}
                onChange={this.props.onGsImageChange}/>
              <TextField className={classes.flexItemInner} type="text" name="imageSource"
                label="Image Source" multiline={true} value={this.props.object.imageSource}
                onChange={this.props.onImageSourceChange}/>
              <TextField className={classes.flexItemInner} type="text" name="title"
                label="Title" multiline={true} value={this.props.object.title}
                onChange={this.props.onTitleChange}/>
              <TextField className={classes.flexItemInner} type="text" name="document"
                label="Document" multiline={true} value={this.props.object.document}
                error={this.props.object.document === undefined || this.props.object.document === ""}
                onChange={this.props.onDocumentChange}/>
              <TextField className={classes.flexItemInner} type="text" name="path"
                label="Path" multiline={true} value={this.props.object.path}/>
            </div>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(YBButton);
