import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import ImageIcon from '@material-ui/icons/Image';
import ButtonIcon from '@material-ui/icons/RadioButtonChecked';
import FillinIcon from '@material-ui/icons/Assignment';
import VerseIcon from '@material-ui/icons/Book';
import LabelIcon from '@material-ui/icons/Label';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//local imports
import Header from './Header.js';
import Image from './Image.js';
import Label from './Label.js';
import Verse from './Verse.js';
import FillInText from './FillInText.js';
import LargeFillin from './LargeFillin.js';
import YBButton from './Button.js';
import YBSpeedDial from './YBSpeedDial';
import history from './History.js';
import 'whatwg-fetch';
import styles from './Style.js';

const actions = [
  { icon: <FillinIcon />, name: 'Fillin', type: 'fillInText' },
  { icon: <VerseIcon />, name: 'Verse', type: 'verse' },
  { icon: <LabelIcon />, name: 'Label', type: 'label' },
  { icon: <FillinIcon />, name: 'Large Fillin', type: 'largeFillIn' },
  { icon: <ImageIcon />, name: 'Image', type: 'image' },
  { icon: <ButtonIcon />, name: 'Button', type: 'button' },
];

function move(arr, old_index, new_index) {
  const newArr = arr.slice();
  newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);
  return newArr;
};

class Notes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedItem: null,
      jsonArray: [],
      date: moment().format('YYYYMMDD'),
    };
    document.title = "You Belong Church Admin";
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated()) {
      history.replace('/');
    }
  }

  onSelect(option) {
    if (option === null) {
      return;
    }

    var obj = {};
    obj.type = option;
    const jsonArray = this.state.jsonArray.slice();
    jsonArray.push(obj);
    this.setState({
      open: !this.state.open,
      jsonArray: jsonArray,
    });
  }

  onDateChange(e) {
    this.setState({date: e.target.value});
  }

  onDelete(key) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray.splice(key,1);
    this.setState({jsonArray: jsonArray});
  }

  onUp(key) {
    if (key !== 0) {
      const jsonArray = move(this.state.jsonArray, key, key-1);
      this.setState({jsonArray: jsonArray});
    }
  }

  onDown(key) {
    if (key !== this.state.jsonArray.length-1) {
      const jsonArray = move(this.state.jsonArray, key, key+1);
      this.setState({jsonArray: jsonArray});
    }
  }

  onLabelChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].title = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onFillinChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].originalText = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onLargeFillinChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].placeholderText = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onVerseChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].verse = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onCitationChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].citation = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onImageSourceChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].imageSource = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onGsImageChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].gsImage = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onDocumentChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].document = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onPreview() {
    const jsonArray = this.state.jsonArray.slice();
    const date = this.state.date;
    var needButtonAlert = false;
    let jsonString = jsonArray.map((item, index) => {
      if (item.type === "image") {
        return {'id': date + '_' + index, 'type': item.type, 'imageSource': item.imageSource, 'gsImage': item.gsImage};
      } else if (item.type === "verse") {
        return {'id': date + '_' + index, 'type': item.type, 'verse': item.verse, 'citation': item.citation};
      } else if (item.type === "label") {
        return {'id': date + '_' + index, 'type': item.type, 'title': item.title};
      } else if (item.type === "fillInText") {
        return {'id': date + '_' + index, 'type': item.type, 'originalText': item.originalText};
      } else if (item.type === "largeFillIn") {
        return {'id': date + '_' + index, 'type': item.type, 'placeholderText': item.placeholderText};
      } else if (item.type === "button") {
        if (item.document === undefined || item.document === "") {
          needButtonAlert = true;
        }
        return {'id': date + '_' + index, 'type': item.type, 'title': item.title, 'document': item.document, 'textPath': item.path, 'gsImage': item.gsImage, 'imageSource': item.imageSource}
      } else {
        return null;
      }
    });

    if (needButtonAlert === true) {
      alert('One or more of your button objects does not have a document set. Please correct it and try again.');
      return;
    }

    let body = {
      document: this.state.date,
      array: jsonString,
    }
    let json = JSON.stringify(body);

    fetch('/centerchurch/notes_upload', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.getIdToken(),
        credentials: 'same-origin',
      },
      body: json,
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.success === true) {
        alert('Preview is now live. Please check the You Belong Church App for accuracy before hitting \'Go Live\'');
      } else {
        alert('An error occurred. ' + response.error);
      }
    })
    .catch((error) => {
      console.error(error);
    });

    //taken from react-download-link: https://github.com/petermoresi/react-download-link/blob/master/download-link.js#L40-L53
    var blob = new Blob([JSON.stringify(jsonString)], {
      type: 'application/json;charset=utf8;'
    });

    // create hidden link
    var element = document.createElement('a');
    document.body.appendChild(element);
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.setAttribute('download', "notes.json");
    element.style.display = '';

    element.click();

    document.body.removeChild(element);
  }

  onGoLive() {
    fetch('/centerchurch/golive', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.getIdToken(),
        credentials: 'same-origin',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          alert('Your updates are now live!');
        } else {
          alert('An error occurred, please contact support.');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  render() {
    const { classes } = this.props;
    //build the components to render the full UI
    let itemsArray = this.state.jsonArray.map((item, index) => {
      if (item.type === "image") {
        return <Image key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onImageSourceChange={this.onImageSourceChange.bind(this, index)}
          onGsImageChange={this.onGsImageChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}/>;
      } else if (item.type === "verse") {
        return <Verse key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onVerseChange={this.onVerseChange.bind(this, index)}
          onCitationChange={this.onCitationChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}/>;
      } else if (item.type === "label") {
        return <Label key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onLabelChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}/>;
      } else if (item.type === "fillInText") {
        return <FillInText key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onFillinChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}/>;
      } else if (item.type === "largeFillIn") {
        return <LargeFillin key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onLargeFillinChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}/>;
      } else if (item.type === "button") {
        return <YBButton key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onImageSourceChange={this.onImageSourceChange.bind(this, index)}
          onTitleChange={this.onLabelChange.bind(this, index)}
          onGsImageChange={this.onGsImageChange.bind(this, index)}
          onDocumentChange={this.onDocumentChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}/>;
      } else {
        return null;
      }
    });

    const options = [
      <TextField key="headerDate" className={classes.headerButton} type="text" name="datePrefix" label="Date"
        defaultValue={this.state.date} onChange={this.onDateChange.bind(this)}
        InputProps={{ className: classes.input }} InputLabelProps={{ className: classes.input }}/>,
      <Button key="headerPreviewButton" onClick={this.onPreview.bind(this)}
        color="secondary" variant="contained" className={classes.headerButton}>Preview</Button>,
      <Button key="headerLiveButton" onClick={this.onGoLive.bind(this)}
        color="secondary" variant="contained" className={classes.headerButton}>Go Live!</Button>
    ];

    return (
      <React.Fragment>
        <CssBaseline />
        <Header title="You Belong Church - Notes Editor" options={options} auth={this.props.auth}/>
        <div className={classNames(classes.appBarMargin, classes.listHolder)}>{itemsArray}</div>
        <YBSpeedDial actions={actions} onSelect={this.onSelect.bind(this)}/>
     </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(Notes)
