import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './Style.js';

class Header extends React.Component {

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  render() {
    const {classes} = this.props;
    const { isAuthenticated } = this.props.auth;
    return <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
            {this.props.title}
          </Typography>
          {this.props.options}
          {
            !isAuthenticated() && (
                <Button variant="contained" color="secondary" onClick={this.login.bind(this)}>
                  Log In
                </Button>
              )
          }
          {
            isAuthenticated() && (
                <Button variant="contained" color="secondary" onClick={this.logout.bind(this)}>
                  Log Out
                </Button>
              )
          }
        </Toolbar>
      </AppBar>
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(Header);
