import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListControls from './ListControls.js';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './Style.js';

class ConnectionLargeFillin extends React.Component {
  render() {
    const {classes} = this.props;
    var placeholderText = this.props.object.title || "Put a placeholder here";
    var idField = null;
    if (this.props.variant === "connection") {
      placeholderText = this.props.object.title;
      idField = <TextField className={classes.flexItemInner} type="text" name="id"
                  label="ID" multiline={true} value={this.props.object.id}
                  onChange={this.props.onIdChange}/>
    }
    return (
      <React.Fragment>
        <Card raised={true} className={classes.card}>
          <CardHeader
            title="Large Fill In"
            classes={{
              root: classes.cardHeader,
              action: classes.action,
            }}
            action={
              <ListControls onDelete={this.props.onDelete}
                onUp={this.props.onUp} onDown={this.props.onDown}/>
            }
          />
          <CardContent className={classes.cardContent}>
            <Card raised={true} className={classes.subcard}>
                {placeholderText}
            </Card>
          </CardContent>
          <CardActions>
            <div className={classes.flexItem}>
              {idField}
              <TextField className={classes.flexItemInner} type="text" name="placeholderText"
                placeholder="Placeholder" value={this.props.object.title}
                onChange={this.props.onChange}/>
            </div>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(ConnectionLargeFillin);
