import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Notes from './Notes.js';
import Connection from './Connection.js';
import Header from './Header.js';
import Pitch from './Pitch.js';
import styles from './Style.js';

const cards = [
  {
    title: 'Connection Card',
    description: ['Manage your weekly information packet'],
    type: "connection",
    link: "Connection",
  },
  {
    title: 'Notes',
    description: [
      'Manage your weekly message outline for users',
    ],
    type: "notes",
    link: "Notes",
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.onClick.bind(this);
    this.state = {
      selectedItem: "home",
    };
    document.title = "You Belong Church Admin";
  }

  onClick(card) {
    this.setState({
      selectedItem: card.type,
    });
  }

  render() {
    const {classes} = this.props;
    const { isAuthenticated } = this.props.auth;
    var display = null
    var subtext = 'Welcome to the admin page for You Belong Church. ' +
      (isAuthenticated()
      ? 'Use the options below to manage and update your content.'
      : 'Please log in.');
    if (this.state.selectedItem === "home") {
      display = <React.Fragment>
      <CssBaseline />
      <Header title="You Belong Church" auth={this.props.auth}/>
      <main className={classes.appBarMargin}>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography variant="h2" align="center" color="textPrimary" gutterBottom>
            You Belong Church Admin
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
            {subtext}
            </Typography>
          </div>
        </div>
        {!isAuthenticated() && (
          <Pitch />
        )}
        {isAuthenticated() && (
          <div className={classNames(classes.layout, classes.cardGrid)}>
              <Grid container spacing={40}>
                {cards.map(card => (
                  <Grid item key={card.title} sm={6} md={6} lg={6}>
                    <Link to={card.link} className={classes.homeLink}>
                      <Card className={classes.card}>
                        <CardHeader
                          title={card.title}
                          titleTypographyProps={{ align: 'center' }}
                          subheaderTypographyProps={{ align: 'center' }}
                          className={classes.cardHeader}
                          />
                        <CardContent>
                          {card.description.map(line => (
                            <Typography variant="subtitle1" align="center" key={line}>
                            {line}
                            </Typography>
                          ))}
                        </CardContent>
                      </Card>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </div>
        )}
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
      <Typography variant="h6" align="center" gutterBottom>
      © 2019  You Belong Church
      </Typography>
      <Typography variant="caption" align="center" color="textSecondary" component="p">
      Everyone's Welcome. Nobody's Perfect. Anything's Possible.
      </Typography>
      </footer>
      {/* End footer */}
      </React.Fragment>
    } else if (this.state.selectedItem === "notes") {
      display = <Notes/>
    } else if (this.state.selectedItem === "connection") {
      display = <Connection />
    }
    return display;
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withRouter(withStyles(styles)(Home));
