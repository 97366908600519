import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import ImageIcon from '@material-ui/icons/Image';
import ButtonIcon from '@material-ui/icons/RadioButtonChecked';
import FillinIcon from '@material-ui/icons/Assignment';
import LabelIcon from '@material-ui/icons/Label';
import CheckIcon from '@material-ui/icons/Check';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import history from './History.js';

//local imports
import Checkbox from './Checkbox.js';
import Date from './Date.js';
import Header from './Header.js';
import Image from './Image.js';
import Label from './Label.js';
import FillIn from './FillIn.js';
import ConnectionLargeFillin from './ConnectionLargeFillIn.js';
import PostButton from './PostButton.js';
import YBSpeedDial from './YBSpeedDial.js';
import 'whatwg-fetch';
import styles from './Style.js';

const actions = [
  { icon: <FillinIcon />, name: 'Fillin', type: 'fillIn' },
  { icon: <CheckIcon />, name: 'Checkbox', type: 'checkbox' },
  { icon: <LabelIcon />, name: 'Label', type: 'label' },
  { icon: <FillinIcon />, name: 'Large Fillin', type: 'largeFillIn' },
  { icon: <ImageIcon />, name: 'Image', type: 'image' },
  { icon: <ButtonIcon />, name: 'Button', type: 'postButton' },
  { icon: <CalendarIcon />, name: 'Date', type: 'date' },
];

function move(arr, old_index, new_index) {
  const newArr = arr.slice();
  newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);
  return newArr;
};

class Connection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSelectedItem: null,
      jsonArray: [],
      date: moment().format('YYYYMMDD'),
    };
    document.title = "You Belong Church Admin";
    const { isAuthenticated } = this.props.auth;
    if (!isAuthenticated()) {
      history.replace('/');
    }
  }

  componentDidMount() {
    fetch('/centerchurch/connectionFetch', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.getIdToken(),
        credentials: 'same-origin',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        var jsonArray = Object.keys(response).map(key => {
          return response[key];
        }).filter(item => {
          return (typeof item !== "boolean");
        }).sort(function(a,b) {
          return a.index - b.index;
        });
        this.setState({
          jsonArray: jsonArray,
        })
      })
      .catch((error) => {
        console.error(error);
      });
  }

  onSelect(option) {
    if (option === null) {
      return;
    }

    var obj = {};
    obj.type = option;
    const jsonArray = this.state.jsonArray.slice();
    jsonArray.push(obj);
    this.setState({
      jsonArray: jsonArray,
    });
  }

  onDateChange(e) {
    this.setState({date: e.target.value});
  }

  onDelete(key) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray.splice(key,1);
    this.setState({jsonArray: jsonArray});
  }

  onUp(key) {
    if (key !== 0) {
      const jsonArray = move(this.state.jsonArray, key, key-1);
      this.setState({jsonArray: jsonArray});
    }
  }

  onDown(key) {
    if (key !== this.state.jsonArray.length-1) {
      const jsonArray = move(this.state.jsonArray, key, key+1);
      this.setState({jsonArray: jsonArray});
    }
  }

  onLabelChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].title = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onFillinChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].title = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onLargeFillinChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].title = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onImageSourceChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].imageSource = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onGsImageChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].gsImage = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onCheckboxChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].title = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onUrlChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].postUrl = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onIdChange(key, e) {
    const jsonArray = this.state.jsonArray.slice();
    jsonArray[key].id = e.target.value;
    this.setState({jsonArray: jsonArray});
  }

  onPreview() {
    const jsonArray = this.state.jsonArray.slice();
    let jsonString = jsonArray.map((item, index) => {
      if (item.type === 'image') {
        return {'id': item.id, 'index': index, 'type': item.type, 'imageSource': item.imageSource, 'gsImage': item.gsImage}
      } else if (item.type === 'checkbox') {
        return {'id': item.id, 'index': index, 'type': item.type, 'title': item.title}
      } else if (item.type === 'label') {
        return {'id': item.id, 'index': index, 'type': item.type, 'title': item.title}
      } else if (item.type === 'fillIn') {
        return {'id': item.id, 'index': index, 'type': item.type, 'title': item.title}
      } else if (item.type === 'largeFillIn') {
        return {'id': item.id, 'index': index, 'type': item.type, 'title': item.title}
      } else if (item.type === 'postButton') {
        return {'id': item.id, 'index': index, 'type': item.type, 'title': item.title, 'postUrl': item.postUrl}
      } else if (item.type === 'date') {
        return {'id': item.id, 'index': index, 'type': item.type}
      } else {
        console.log(item.type);
        return null;
      }
    });
    var jsonObject = {};
    jsonString.forEach((item, index) => {
      jsonObject[item.id] = item
    })
    jsonObject['public'] = true;

    let body = {
      document: this.state.date,
      object: jsonObject,
    }
    let json = JSON.stringify(body);

    fetch('/centerchurch/connectionUpload', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.getIdToken(),
        credentials: 'same-origin',
      },
      body: json,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          alert('Preview is now live. Please check the You Belong Church App for accuracy before hitting \'Go Live\'');
        } else {
          alert('An error occurred. ' + response.error);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    //taken from react-download-link: https://github.com/petermoresi/react-download-link/blob/master/download-link.js#L40-L53
    var blob = new Blob([JSON.stringify(jsonString)], {
      type: 'application/json;charset=utf8;'
    });

    // create hidden link
    var element = document.createElement('a');
    document.body.appendChild(element);
    element.setAttribute('href', window.URL.createObjectURL(blob));
    element.setAttribute('download', "connection.json");
    element.style.display = '';

    element.click();

    document.body.removeChild(element);
  }

  onGoLive() {
    fetch('/centerchurch/connectionGoLive', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.auth.getIdToken(),
        credentials: 'same-origin',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          alert('Your updates are now live!');
        } else {
          alert('An error occurred, please contact support.');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  render() {
    const { classes } = this.props;
    //build the components to render the full UI
    let itemsArray = this.state.jsonArray.map((item, index) => {
      if (item.type === "image") {
        return <Image key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onImageSourceChange={this.onImageSourceChange.bind(this, index)}
          onGsImageChange={this.onGsImageChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)} variant="connection"/>;
      } else if (item.type === "label") {
        return <Label key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onLabelChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)}
          variant="connection"/>;
      } else if (item.type === "fillIn") {
        return <FillIn key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onFillinChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)}/>;
      } else if (item.type === "largeFillIn") {
        return <ConnectionLargeFillin key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onLargeFillinChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)}/>;
      } else if (item.type === "checkbox") {
        return <Checkbox key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onChange={this.onCheckboxChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)}/>;
      } else if (item.type === "date") {
        return <Date key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)}/>
      } else if (item.type === "postButton") {
        return <PostButton key={index.toString()} id={index} onDelete={() => this.onDelete(index)}
          object={item} onTitleChange={this.onFillinChange.bind(this, index)}
          onUp={() => this.onUp(index)} onDown={() => this.onDown(index)}
          onIdChange={this.onIdChange.bind(this, index)}
          onUrlChange={this.onUrlChange.bind(this, index)}/>;
      } else {
        return null;
      }
    });

    const options = [
      <TextField key="headerDate" className={classes.headerButton} type="text" name="datePrefix" label="Date"
        defaultValue={this.state.date} onChange={this.onDateChange.bind(this)}
        InputProps={{ className: classes.input }} InputLabelProps={{ className: classes.input }}/>,
      <Button key="headerPreviewButton" onClick={this.onPreview.bind(this)}
        color="secondary" variant="contained" className={classes.headerButton}>Preview</Button>,
      <Button key="headerLiveButton" onClick={this.onGoLive.bind(this)}
        color="secondary" variant="contained" className={classes.headerButton}>Go Live!</Button>
    ];

    return (
      <React.Fragment>
        <CssBaseline />
        <Header title="You Belong Church - Connection Card Editor" options={options} auth={this.props.auth}/>
        <div className={classNames(classes.appBarMargin, classes.listHolder)}>{itemsArray}</div>
        <YBSpeedDial actions={actions} onSelect={this.onSelect.bind(this)}/>
     </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(Connection)
