import React from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { withStyles } from '@material-ui/core/styles';
import styles from './Style.js';

class YBSpeedDial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      hidden: false,
      actions: this.props.actions
    };
  }

  handleOpen = () => {
    if (!this.state.hidden) {
      this.setState({
        open: true,
      });
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { hidden, open } = this.state;
    const { classes } = this.props;
    return <div className={classes.fabHolder}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        hidden={hidden}
        icon={<SpeedDialIcon />}
        onBlur={this.handleClose}
        onClick={this.handleClick}
        onClose={this.handleClose}
        onFocus={this.handleOpen}
        onMouseEnter={this.handleOpen}
        onMouseLeave={this.handleClose}
        open={open}
        >
        {this.state.actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => this.props.onSelect(action.type)}
          />
        ))}
      </SpeedDial>
    </div>
  };
}

export default withStyles(styles)(YBSpeedDial);
