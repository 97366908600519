import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListControls from './ListControls.js';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './Style.js';

class PostButton extends React.Component {
  render() {
    const {classes} = this.props;
    var originalText = this.props.object.title;
    return (
      <React.Fragment>
        <Card raised={true} className={classes.card}>
          <CardHeader
            title="Button"
            classes={{
              root: classes.cardHeader,
              action: classes.action,
            }}
            action={
              <ListControls onDelete={this.props.onDelete}
                onUp={this.props.onUp} onDown={this.props.onDown}/>
            }
          />
          <CardContent className={classes.cardContent}>
            <Card raised={true} className={classes.buttonSubcard}>
                {originalText}
            </Card>
          </CardContent>
          <CardActions>
            <div className={classes.flexItem}>
              <TextField className={classes.flexItemInner} type="text" name="id"
                label="ID" multiline={true} value={this.props.object.id}
                onChange={this.props.onIdChange}/>
              <TextField className={classes.flexItemInner} type="text" name="title"
                label="Title" multiline={true} onChange={this.props.onTitleChange}
                value={this.props.object.title}/>
              <TextField className={classes.flexItemInner} type="text" name="postUrl"
                label="Post URL" multiline={true} onChange={this.props.onUrlChange}
                value={this.props.object.postUrl}/>
            </div>
          </CardActions>
        </Card>
    </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(PostButton);
