
const styles = theme => ({
  appBar: {
    position: 'fixed',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    position: 'fixed',
    bottom: 0,
    width: '100%'
  },
  homeLink: {
    textDecoration: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  input: {
    color: "white"
  },
  headerButton: {
    marginRight: 24,
  },
  appBarMargin: {
    marginTop: 74,
    marginBottom: 108,
  },
  listHolder: {
    marginRight: 20,
    marginLeft: 20,
    paddingBottom: 40,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 3,
  },
  fabHolder: {
    bottom: '40px',
    right: '40px',
    position: 'fixed',
    float: 'right',
  },
  card: {
    height: '100%',
    maxWidth: 720,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  subcard: {
    padding: 10,
  },
  citation: {
    fontSize: 8,
    textAlign: 'end',
    alignItems: 'flex-end',
    fontFamily: [
      '"Century Gothic"',
      'Futura',
      'sans-serif',
    ].join(','),
  },
  action: {
    marginTop: 0,
    marginRight: 0,
  },
  checkbox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkboxIcon: {
    marginRight: '10px',
  },
  image: {
    width: '100%',
  },
  buttonSubcard: {
    padding: 10,
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.grey[100],
  },
  notesButton: {
    width: '100%',
    height: 76,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  roundImage: {
    marginTop: 8,
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 16,
    height: 60,
  },
  flexItemInner: {
    width: '100%',
    marginTop: 15,
  },
  flexItem: {
    webkitOrder: 0,
    msFlexOrder: 0,
    order: 0,
    webkitFlex: '0 1 720px',
    msFlex: '0 1 720px',
    flex: '0 1 720px',
    webkitAlignSelf: 'auto',
    msFlexItemAlign: 'auto',
    alignSelf: 'auto',
    verticalAlign: 'middle',
    padding: 4,
    borderRadius: 4,
  },
  pitchGrid: {
    margin: '0 auto',
  },
  pitchMission: {
    margin: '0 auto',
    flexGrow: 1,
    backgroundColor: theme.palette.primary.main,
    marginBottom: 0,
  },
  pitchMissionText: {
    color: '#FFFFFF',
  },
  pitchMissionImage: {
    marginBottom: -24
  },
  pitchMissionQuote: {
    marginLeft: 20,
  },
  pitchSignup: {
  },
  pitchHeader: {
    position: 'relative',
    alignItems: 'center',
    background: 'url('+require('./images/hero.jpg')+') no-repeat right',
    flex: 1
  },
  pitchHeaderGridHolder: {
    paddingTop: 80,
    paddingBottom: 80,
    paddingRight:40,
    paddingLeft:40,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
  },
  pitchHeaderGrid: {
    alignItems: 'center',
    height: '100%',
  },
  pitchHeaderText: {
    color: '#FFFFFF',
  }
});

export default styles;
