import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './index.css';
import Main from './Main.js';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#073056',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  contrastText: '#ffcc00',
  overrides: {
    MuiFormControl: {
      root: {
        marginTop: 0,
      }
    }
  }
});

const App = () => (
    <MuiThemeProvider theme={theme}>
      <Main/>
    </MuiThemeProvider>
);

// ========================================

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
