import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import Home from './Home.js';
import Connection from './Connection.js';
import Notes from './Notes.js';
import Auth from './Auth.js';
import Callback from './Callback.js';
import history from './History.js';
import * as Sentry from '@sentry/browser';

const auth = new Auth();

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
}

class Main extends React.Component {
  constructor(props) {
      super(props);

      Sentry.init({
        dsn: "https://45b107a715b7492c91366b43f0ec6bb0@sentry.io/1367224"
      });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <main>
        <Router history={history} component={Home}>
          <Switch>
            <Route exact path='/' render={(props) => <Home auth={auth} />}/>
            <Route path='/connection' render={(props) => <Connection auth={auth} />}/>
            <Route path='/notes' render={(props) => <Notes auth={auth} />}/>
            <Route path="/callback" render={(props) => {
              handleAuthentication(props);
              return <Callback {...props} />
            }}/>
          </Switch>
        </Router>
      </main>
    );
  }
}

export default Main;
