import React from 'react';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ListControls from './ListControls.js';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './Style.js';

class Verse extends React.Component {
  render() {
    const {classes} = this.props;
    const verseText = this.props.object.verse ||
      "A good name is rather to be chosen than great riches, and loving favour rather than silver and gold.loving…: or, favour is better than, etc. The rich and poor meet together: the Lord is the maker of them all.";
    const citationText = this.props.object.citation || "Proverbs 22:1-2 (KJV)";
    return (
      <React.Fragment>
        <Card raised={true} className={classes.card}>
          <CardHeader
            title="Verse"
            classes={{
              root: classes.cardHeader,
              action: classes.action,
            }}
            action={
              <ListControls onDelete={this.props.onDelete}
                onUp={this.props.onUp} onDown={this.props.onDown}/>
            }
          />
          <CardContent className={classes.cardContent}>
            <Card raised={true} className={classes.subcard}>
              <div className={classes.verse}>
                {verseText}
              </div>
              <div className={classes.citation}>
                {citationText}
              </div>
            </Card>
          </CardContent>
          <CardActions>
            <div className={classes.flexItem}>
              <TextField className={classes.flexItemInner} type="text" name="verse"
                label="Verse" multiline={true} value={this.props.object.verse}
                onChange={this.props.onVerseChange}/>
              <TextField className={classes.flexItemInner} type="text" name="citation"
                label="Citation" multiline={true} value={this.props.object.citation}
                onChange={this.props.onCitationChange}/>
            </div>
          </CardActions>
        </Card>
      </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(Verse);
