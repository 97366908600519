import React, { Component } from 'react';
// import loading from './loading.svg';

class Callback extends Component {
  render() {

    return (
      <div />
    );
  }
}

export default Callback;
