import React from 'react';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import styles from './Style.js';
import hero from './images/heroImage.png';
import map from './images/mapCutoff.png';
import appStore from './images/appStore.png';
import playStore from './images/playStore.png';

class Pitch extends React.Component {
  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <section className={classes.pitchHeader}>
          <div className={classes.pitchHeaderGridHolder}>
            <Grid container item xs={12} sm={6} className={classes.pitchHeaderGrid} justify='center' zeroMinWidth>
              <Grid container item direction='column' spacing={40} zeroMinWidth>
                <Grid item xs>
                  <Typography className={classes.pitchHeaderText} variant='h5' align='center'>
                    You Belong is a powerful new app framework designed from the ground
                    up to support churches of all sizes with a simple, reliable and
                    affordable mobile experience.
                  </Typography>
                </Grid>
                <Grid container item spacing={0}>
                  <Grid item xs align='center'>
                    <a href='https://itunes.apple.com/us/app/you-belong-church/id1143419397?mt=8' className={classes.homeLink}>
                      <img src={appStore} alt='Apple App Store'/>
                    </a>
                  </Grid>
                  <Grid item xs align='center'>
                    <a href='https://play.google.com/store/apps/details?id=com.doublekaudio.you_belong' className={classes.homeLink}>
                      <img src={playStore} alt='Google Play Store'/>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </section>
        <Grid className={classes.pitchGrid} item xs={12} sm={12} md={9} container spacing={40} justify='center' alignItems='center' alignContent='center'>
          <Grid item xs={12} sm={12} md={4} container direction='column' spacing={16}>
            <Grid item xs>
              <Typography gutterBottom variant='h5' align='right'>
                Online Giving
              </Typography>
              <Typography align='right'>
                Using our flexible external linking, you no longer need to
                point your church to another app to do their giving. You Belong
                can link out directly to your church’s page on Secure Give,
                Push Pay or Planning Center Giving.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant='h5' align='right'>
                Daily Bible Reading
              </Typography>
              <Typography align='right'>
                Utilizing the ESV daily scripture tools,
                your congregation can use the You Belong
                app to read through the Bible in a year.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant='h5' align='right'>
                Location Information
              </Typography>
              <Typography align='right'>
                You Belong includes mapping functionality to show all your locations,
                complete with addresses and directions. Having a special event
                in a rented space? You can even add that new location to the
                Directions page temporarily, providing one master source of
                location information to your members.
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
              <img className={classes.image} alt='complex' src={hero} />
          </Grid>
          <Grid item xs={12} sm={12} md={4} container direction='column' spacing={16}>
            <Grid item xs>
              <Typography gutterBottom variant='h5' align='left'>
                Message Notes
              </Typography>
              <Typography align='left'>
                Using our flexible external linking, you no longer need to
                point your church to another app to do their giving. You Belong
                can link out directly to your church’s page on Secure Give,
                Push Pay or Planning Center Giving.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant='h5' align='left'>
                Digital Attendance
              </Typography>
              <Typography align='left'>
                Utilizing the ESV daily scripture tools,
                your congregation can use the You Belong
                app to read through the Bible in a year.
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography gutterBottom variant='h5' align='left'>
                Links to Partners and Resources
              </Typography>
              <Typography align='left'>
                You Belong includes mapping functionality to show all your locations,
                complete with addresses and directions. Having a special event
                in a rented space? You can even add that new location to the
                Directions page temporarily, providing one master source of
                location information to your members.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.pitchMission}>
          <Grid className={classes.pitchGrid} item xs={9} container spacing={40} justify='center' alignItems='center' alignContent='center'>
            <Grid item xs={12} sm={6}>
              <img src={map} alt='map' className={classNames(classes.image, classes.pitchMissionImage)}/>
            </Grid>
            <Grid item xs={12} sm={6} container direction='column' spacing={16}>
              <Grid item>
                <Typography className={classes.pitchMissionText} gutterBottom variant='h4'>
                  Our Mission
                </Typography>
                <Typography className={classes.pitchMissionText} align='left' gutterBottom>
                  At You Belong, our goal is to provide you and your congregation
                  with the tools to communicate and grow effectively. We strive with
                  every line of code to build a truly wonderful user experience that
                  maintains the highest quality, while remaining affordable to even
                  the most modest of church budgets.
                </Typography>
                <br/>
                <Typography className={classes.pitchMissionText} align='left' gutterBottom>
                  We believe with all our heart that this tool can help churches
                  grow and interact with their community. If there’s a tool we’ve missed,
                  we’d love to hear your feedback. Here’s a couple great notes we’ve
                  received from our first church adopters:
                </Typography>
                <br/>
                <Typography className={classNames(classes.pitchMissionText, classes.pitchMissionQuote)} align='left' gutterBottom>
                  “The app makes me pay attention in church. Before You Belong,
                  my ADD tended to get the better of me. Nevermind that my
                  brother-in-law is the pastor, I still couldn’t focus! Now,
                  with the You Belong app, I’m fully engaged and participating
                  in service every week. Even better, my kids love it!”<br/>
                  – JN, Center Church San Diego
                </Typography>
                <br/>
                <Typography className={classNames(classes.pitchMissionText, classes.pitchMissionQuote)} align='left' gutterBottom>
                  “Shortly after we launched the app, I was taking my kids home
                  from Sunday services and my oldest was very excited to share
                  with me about the cool new app she’d found for Center Church!
                  While I was directly involved in the direction and content of
                  the You Belong app, it was wonderful to hear the next generation
                  whole-heartedly adopting the new platform.”<br/>
                  – Marcus Jones, Pastor of Center Church San Diego
                </Typography>
                <br/>
                <Typography className={classes.pitchMissionText} align='left' gutterBottom>
                  We think that if you give You Belong a try, you’ll be just as
                  sold as these guys were. The days where having a website was
                  enough are over. Tech-savvy young churchgoers are demanding a
                  real app experience and a way to interact with and share your
                  content. You Belong is the remedy to that need, sign your church
                  up today!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={classes.pitchSignup}>
          <iframe title='emailSignup' src='https://www.powr.io/plugins/mailing-list/view?unique_label=3bb09b09_1504678715&external_type=material' width='100%' height='675px' frameBorder='0'></iframe>
        </div>
      </React.Fragment>
    );
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };
}

export default withStyles(styles)(Pitch);
